import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

import SEO from "../components/seo"
import { SmallHeroLayout } from "../components/layouts"
import { BaseModal } from "../components/generic/base-modal"

const Container = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h1 {
    margin-bottom: ${(p) => (p.isLoading ? "0" : "1rem")};
  }

  & > .back-button {
    cursor: pointer;
    visibility: ${(p) => (p.isLoading ? "hidden" : "visible")};
    margin-top: 1rem;
    background-color: transparent;
    padding: 0.5rem;
    border: none;
    color: white;
    font-size: 0.75rem;
    text-decoration: underline;
  }

  & > .back-button:hover {
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.075);
  }
`

const ScriptAnchor = styled.div<{ isLoading?: boolean }>`
  visibility: ${(p) => (p.isLoading ? "hidden" : "visible")};
`

const StudentPortalPage = () => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (typeof window !== "undefined" && !!window) {
      console.log("useEffect")
      const tag = document.createElement("script")
      tag.type = "text/javascript"
      tag.src = "https://app.mymusicstaff.com/Widget/v2/Login.ashx"

      const anchor = document.getElementById("script-anchor")
      anchor?.appendChild(tag)

      tag.onload = () => {
        setTimeout(() => {
          // pause before rendering to let script elements mount
          setLoading(false)
        }, 1500)
      }
    }
  }, [])

  return (
    <SmallHeroLayout pageTitle="Student Portal">
      <SEO title="Student Portal | Six Strings Townsville | Guitar School" />
      <BaseModal visible onClose={() => !!window && window.history.go(-1)}>
        <Container isLoading={isLoading}>
          <h1>{isLoading ? "Loading..." : "Student Portal"}</h1>
          <ScriptAnchor isLoading={isLoading} id="script-anchor" />
        </Container>
      </BaseModal>
    </SmallHeroLayout>
  )
}

export default StudentPortalPage
